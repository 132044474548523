<template>
    
    <v-container fluid class="">

        <v-row no-gutters v-if="!etapaEdicaoGuia">
			<v-col cols="12" sm="12" md="8" lg="8" xl="8">
				<v-toolbar-title class="ml-2 mt-2"
					>GUIA DE BOLSO - ARQUIVOS DE IMPORTAÇÕES</v-toolbar-title
				>
			</v-col>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
				<v-btn class="ml-2 my-1" :disabled="!permiteNovo" color="primary" @click="criarNovo()">
					<v-icon left>mdi-file</v-icon> Novo
				</v-btn>
				
			</v-col> 
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1" v-if="!etapaEdicaoGuia">
			
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="guiadebolso_id"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						small
						class="mr-2"
						color="primary"
						@click="abrir(item)"
						title="Abrir"
					>
						mdi-folder
					</v-icon>
				</template>
			</v-data-table>
		</v-card>


        <v-row no-gutters v-if="etapaEdicaoGuia">
            <v-col cols="10">
                <v-tabs
                    class="info-tabs"
                    active-class="activeMyClass"
                    background-color="transparent"
                    center-active
                    v-model="tabUrl"
                >
                    <v-tab @click="goTo('planilhaferramentas')" value="/guiadebolso/ferramentas" >
                        Planilha Ferramentas
                    </v-tab>
                    <v-tab @click="goTo('planilhafarmacias')"  value="/guiadebolso/farmacias">
                        Planilha Farmácias
                    </v-tab>
                    <v-tab @click="goTo('materiaisleves')"  value="/guiadebolso/materiaisleves">
                        Planilha Materiais Leves
                    </v-tab>
                    <v-tab @click="goTo('pontosextras')"  value="/guiadebolso/pontosextras">
                        Pontos Extras
                    </v-tab>
                    <v-tab @click="goTo('planilhaguia')"  value="/guiadebolso/guia">
                        Planilha Guia de Bolso
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="2" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="fecharGuia()">
					<v-icon left>mdi-close</v-icon> Fechar 
				</v-btn>
				
			</v-col> 
        </v-row>
        <v-row v-show="etapaEdicaoGuia">
            <v-col cols="12" class="pa-0">
                <template>
                    <router-view></router-view>
                </template>
            </v-col>
        </v-row>
    </v-container> 

</template>

<script>
export default {
    data() {
        return {
            busca: '',
            mes: '',
            total: 0,
            pagination: {},
            headers: [
				{ text:"Data Criação", value: "data_criacao", align: "center" },
                { text:"Data Importação", value: "data_importacao", align: "center" },
                { text: "", value: "botao", align: "center", sortable: false },
            ],    
            dados: [],
            etapaGuias: true,
            // etapaEdicaoGuia: false,
            guiadebolso_id: 0,
            permiteNovo: false,
            tabUrl: '/guiadebolso/ferramentas'
        };
    },
    methods: {
        abrir(item) {
            this.goTo('planilhaferramentas')
            localStorage.guiadebolso_id = item.guiadebolso_id
            this.etapaGuias = false
            // this.etapaEdicaoGuia = true
        },
        async abrirGuias() {
            this.loadLista = true;
            this.permiteNovo = true
            var response = await this.$http().get("/importacaoinventario/lista");

            if ((response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha buscar os dados, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.loadLista = false;
                return null;
            }
            this.dados = response.data
            this.guiadebolso_id = response.data.length > 0 ? response.data[0].guiadebolso_id : 0
            this.permiteNovo = true
            if (response.data) {
                this.permiteNovo = true
                for (var item of response.data) {
                    if (item.data_importacao.length == 0) {
                        this.permiteNovo = false
                    }
                }
            }
            this.loadLista = false;
        },
        fecharGuia() {
            this.abrirGuias() 
            this.goTo('guiadebolso')
        },
        async criarNovo() {
            this.loadLista = true;

            var response = await this.$http().get("/importacaoinventario/novo");

            if ((response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha ao tentar criar um novo, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.loadLista = false;
                return null;
            }
            localStorage.guiadebolso_id = response.data ? response.data.guiadebolso_id : 0
            this.permiteNovo = false
            this.loadLista = false;
            this.goTo('planilhaferramentas')
            this.etapaGuias = false
            this.etapaEdicaoGuia = true
        },
        goTo(rota) {
            this.tabUrl = rota
            this.$router.push({ name: rota });
        },
    },
    computed: {
        etapaEdicaoGuia() {
            return this.$route.path !== '/guiadebolso'
        }
    },
    async mounted() {
        this.tabUrl = this.$route.path ? this.$route.path : ''
        this.abrirGuias()     
    }

};
</script>


<style>
.info-tabs .activeMyClass:hover:before {
    background: none;
}
.info-tabs .v-tab:before {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.info-tabs .v-tabs-slider {
    width: 70%;
    margin: 0 15%;
}
.info-tabs .v-tab {
    width: 250px;
    font-size: 1.2rem;
    font-weight: normal !important;
    letter-spacing: 0px;
    color: #999 !important;
    text-transform: none !important;
}
.info-tabs .activeMyClass {
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #000 !important;
}
</style>
